<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <div
            class="second-header"
            v-if="cartList.length"
        >
            <checkbox
                v-if="singleSupplier||isEdit"
                v-model="checkedAll"
            >
                全选
            </checkbox>
            <div
                class="fw-bold"
                v-else
            >
                购物车
            </div>
            <div
                class="btn btn-main radius link bor"
                v-if="!isEdit"
                @click="onEdit"
            >
                编辑
            </div>
            <div
                class="btn btn-green radius"
                v-else
                @click="onComplete"
            >
                完成
            </div>
        </div>
        <container ref="container">
            <!-- 单一供应商 -->
            <template v-if="singleSupplier">
                <template v-for="supplier in supplierList">
                    <CartGoodsItem
                        v-for="(cart) in supplier.children"
                        :key="cart.id"
                        :data="cart"
                        :value="cart.checked"
                        @input="onItemInput(supplier,cart)"
                        :editing="isEdit"
                        @change="onCartChange(cart,$event)"
                        @delete="()=>{onDelete(cart)}"
                    />
                </template>
            </template>
            <template v-else>
                <Panel
                    v-for="supplier in supplierList"
                    :key="supplier.id"
                    class="ma-b"
                >
                    <checkbox
                        slot="label"
                        class="label"
                        :value="supplier.checked"
                        @input="onSupplierSelect(supplier)"
                    >
                        {{ supplier.supplierName }}
                    </checkbox>

                    <CartGoodsItem
                        v-for="(cart) in supplier.children"
                        :key="cart.id"
                        :data="cart"
                        :value="cart.checked"
                        @input="onItemInput(supplier,cart)"
                        :editing="isEdit"
                        @change="onCartChange(cart,$event)"
                        @delete="()=>{onDelete(cart)}"
                    />
                </Panel>
            </template>
            <div
                v-if="!supplierList||!supplierList.length"
                class="ta-c pa-a ma-t"
            >
                <img
                    class="img-obj"
                    style="opacity: .3;"
                    src="~@/assets/images/icon/icon01.png"
                    width="40%"
                >
                <div class="tc-g6">
                    购物车什么也没有
                </div>
                <linker
                    to="/"
                    class="btn btn-main ma-t"
                >
                    去逛逛
                </linker>
            </div>
        </container>
        <template>
            <div
                class="second-footer"
                v-if="isEdit"
            >
                <div
                    class="btn btn-main"
                    v-if="+totalAmount"
                    @click="()=>{onDeleteAll()}"
                >
                    删除选中
                </div>
                <div
                    class="btn btn-g5"
                    v-else
                >
                    删除选中
                </div>
            </div>
            <div
                class="second-footer"
                v-else
            >
                <span class="ma-r">合计：<span class="price">{{ totalAmount | price }}</span></span>
                <div
                    class="btn btn-main"
                    v-if="+totalAmount"
                    @click="()=>{onSubmit()}"
                >
                    提交订单 ({{ selectedSkuQuantity }})
                </div>
                <div
                    class="btn btn-g5"
                    v-else
                >
                    提交订单
                </div>
            </div>
        </template>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import cartStoreMixin from './cartStore';
import CartGoodsItem from './_CartGoodsItem';

export default {
    name: 'Cart',
    mixins: [pageMixin, cartStoreMixin],
    components: { CartGoodsItem },
    data() {
        return {
            isEdit: false,
            selectedJson: '',
            editMap: {},
        };
    },
    computed: {
        checkedAll: {
            get() {
                const { cartList, isEdit } = this;
                if (!cartList || !cartList.length) return false;
                return !cartList.some(item => !(item.checked && (isEdit || item.goodsStatus === 3)));
            },
            set(val) {
                this.setSelected({ value: val, isEdit: this.isEdit });
            },
        },
        selectedSkuQuantity() {
            const { cartList } = this;
            return cartList.reduce((pre, curr) => {
                if (!curr.checked) return pre;
                return pre + curr.quantity;
            }, 0);
        },
        singleSupplier() {
            return this.supplierList.length === 1;
        },
    },
    methods: {
        async onDelete(cart) {
            await this.$messageBox.confirm('是否确认删除', '温馨提示');
            this.deleteCart(cart.id);
        },
        async onDeleteAll() {
            const ids = this.cartList.reduce((pre, curr) => {
                if (curr.checked) pre.push(curr.id);
                return pre;
            }, []);
            await this.$messageBox.confirm('是否确认删除', '温馨提示');
            console.log(ids);
            this.deleteCart(ids);
        },
        onEdit() {
            this.isEdit = true;
            this.selectedJson = this.getSelectedData();
            this.setSelected({ value: false });
            // console.log(this.selectedJson);
        },
        onCartChange(cart, data) {
            if (!this.isEdit) {
                this.editCart({ ...data });
            } else {
                this.editMap[data.id] = { ...data };
            }
        },
        async onComplete() {
            const { editMap } = this;
            const editList = Object.keys(editMap).map(key => editMap[key]);
            if (editList.length) {
                await this.editCart(editList);
            }
            this.editMap = {};
            this.isEdit = false;
            this.restoreSelected(this.selectedJson);
        },
        async onSubmit() {
            const cartJson = this.cartList.reduce((pre, cur) => {
                if (!cur.checked) return pre;
                pre.push({
                    cartId: cur.id,
                    quantity: cur.quantity,
                });
                return pre;
            }, []);
            if (!cartJson.length) return;
            const json = await this.$api.Buyer.Ex.Order.cartConfirm({
                cartJson: JSON.stringify(cartJson),
            });
            const res = json.data.data;
            this.$router.push(`/order/new/${res.uuid}?type=cart`);
        },
        onItemInput(supplier, cart) {
            const { currentSupplier = {}, isEdit } = this;
            this.cartSelect({
                cartId: cart.id,
                currentSupplierId: currentSupplier.id,
                isEdit,
            });
        },
        onSupplierSelect(supplier) {
            const { currentSupplier = {}, isEdit } = this;
            this.supplierSelect({
                supplierId: supplier.id,
                currentSupplierId: currentSupplier.id,
                isEdit,
            });
        },
    },
    activated() {
        this.updated();
    },
};
</script>

<style lang="scss" scoped>
.second-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    background-color: #fff;
}

.second-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    background-color: #fff;
    > .btn {
        align-self: stretch;
    }
}
</style>
