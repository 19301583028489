<!-- Created by henian.xu on 2018/4/18. -->

<template>
    <div class="cart-goods-item">
        <checkbox
            :disabled="editing?false:data.goodsStatus!==3"
            v-model="model"
        />
        <div
            class="img-wrap"
            :style="`background-image: url('${$options.filters.imgCropping(data.specImageUrl || $defaultImg,{width:350})}');`"
        >
            <div
                v-if="data.goodsStatus!==3"
                class="sold-out"
            />
        </div>
        <div class="inner">
            <div class="header">
                <div class="name">
                    {{ data.name }}
                </div>
                <!--<div
                    v-if="editing || data.goodsStatus===3"
                    class="del btn btn-red small"
                    v-show="editing"
                    @click="onDelete"
                >
                    删除
                </div>-->
            </div>
            <div
                class="sku"
                v-if="data.specDesc"
            >
                <span>{{ data.specDesc }}</span>
            </div>
            <div class="price-wrap">
                <div class="price">
                    {{ data.price | price }}
                </div>
                <input-number
                    v-if="data.goodsStatus===3"
                    :no-input="!editing"
                    v-model="buyQuantity"
                    :min="1"
                    :max="data.showStock"
                    @plusdisabled="$messageBox.tips('已超过最大库存数量!')"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CartGoodsItem',
    data() {
        return {
            buyQuantity: this.data.quantity,
            emitChange_: this.$utils.Comm.debounce(this.emitChange),
        };
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Object,
            default: () => ({}),
        },
        editing: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    watch: {
        buyQuantity() {
            this.emitChange_();
        },
    },
    methods: {
        onDelete() {
            this.$emit('delete');
        },
        async emitChange() {
            const { data, buyQuantity } = this;
            this.$emit('change', {
                id: data.id,
                quantity: buyQuantity,
            });
        },
    },
};
</script>

<style lang="scss">
.cart-goods-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    padding: $padding;
    border-top: 1px solid $color-border;
    background-color: #fff;
    &:first-child {
        border-top: 0;
    }
    > .checkbox {
        align-self: center;
    }
    > .img-wrap {
        flex: 0 0 auto;
        width: 1.6rem;
        height: 1.6rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: $margin;

        > .sold-out {
            background: rgba(0, 0, 0, 0.5) url('~@/assets/images/comm/icon02.png') center no-repeat;
            background-size: 70%;
            width: 100%;
            height: 100%;
        }
    }
    > .inner {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        > .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            > .name {
                flex: 1 1 auto;
                line-height: 1.2;
                font-size: 0.3rem;
                @include text-line(2);
            }
            > .del {
                flex: 0 0 auto;
                white-space: nowrap;
                margin-left: $margin-small/2;
            }
        }
        > .sku {
            > span {
                display: inline-block;
                font-size: 0.22rem;
                line-height: 1.5;
                padding: 0 $padding-small;
                border: 1px solid $color-main;
                color: $color-main;
                border-radius: (0.22rem * 1.5)/2;
            }
        }
        > .price-wrap {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}
</style>
